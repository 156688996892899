@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@300&family=Sarabun&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
/* Google font */

body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  box-sizing: border-box;
  background: #eeeeee;
  font-family: "Raleway", "Kanit", "Sarabun", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: #000;
  color: initial;
  text-decoration: none;
}

a:after {
  content: "";
  width: 0%;
  max-width: 100%;
  height: 1px;
  margin: auto;
  display: block;
  background: #333333;
  transition: width 0.3s ease;
}

a:hover:after {
  width: 100%;
}

a.no-underline:after {
  height: 0;
  background: none;
}

img {
  max-width: 100%;
  height: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.kanit {
  font-family: "Kanit";
}

.sarabun {
  font-family: "Sarabun";
}

